import SimpleSchema from 'simpl-schema'

export default {
  _id: {
    type: String,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  metadata: {
    label: 'Metadatos',
    type: Array,
    title: (item) => {
      return item.label + ' / ' + item.name + ' / ' + item.type;
    },
    optional: true,
  },
  'metadata.$': {
    type: {
      name: {
        label: 'Nombre',
        type: String,
      },
      type: {
        label: 'Nombre',
        type: String,
        options: [{
          id: 'String',
          name: 'String',
        }, {
          id: 'Number',
          name: 'Number',
        }, {
          id: 'Boolean',
          name: 'Boolean',
        }, {
          id: 'Photo',
          name: 'Photo',
        }]
      },
      label: {
        label: 'Label',
        type: String,
      },
    },
  },
  imageUp: {
    label: 'Activo <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  imageDown: {
    label: 'Inactivo <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  imageAlert: {
    label: 'Alertado <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  imageReported: {
    label: 'Reportado <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
};
